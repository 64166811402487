import React from "react"
import { Box, Center, Flex, Heading, Icon } from "@chakra-ui/react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
const Footer = ({ location }) => {
  return (
    <Box minH={60} bgColor="#333">
      <Flex
        maxW="1240px"
        margin="0 auto"
        color="#fff"
        justifyContent="space-between"
        px={8}
        pt={9}
      >
        {/* left  */}
        <Box>
          {/* <StaticImage
            src={"../images/MindfulnessCapital_newfooter.png"}
            // src={"../images/MindfulnessCapital_footer.png"}
            alt="MindfulnessCapitalfooter"
            placeholder="blurred"
            // width={140}
            // height={27}
          /> */}
          <Box pt={2} style={{ paddingTop: "0px" }}>
            {location.pathname === "/"
              ? "Contact Us"
              : location.pathname === "/simplifiedChinese"
                ? "联系我们"
                : "聯繫我們"}
          </Box>
          <Box fontSize={{ base: "10px" }}>info@miam.network</Box>
          <Box fontSize={{ base: "10px" }}>   {location.pathname === "/"
              ? "Tel"
              : location.pathname === "/simplifiedChinese"
                ? "电话"
                : "電話"}: +853 65212829</Box>
        </Box>

        <Box w={{ base: 10, sm: 6, md: 6 }} />
        {/* right  */}

        <Box mr={{ sm: 0, md: 28 }}>
          <Box fontSize="sm">
            {location.pathname === "/"
              ? "Social Media"
              : location.pathname === "/simplifiedChinese"
                ? "社交媒体"
                : "社交媒體"}
          </Box>
          <Flex mt={5}>

            <Box
              cursor="pointer"
              w={{ base: "24px", sm: "24px", lg: "24px" }}
              onClick={() => {
                navigate("https://www.facebook.com/Metaverse-Industry-Association-Macau-111953058178553")
              }}
            >
              <StaticImage
                src={"../images/facebook.png"}
                alt="facebook"
                placeholder="blurred"
              />
            </Box>

            <Box w={{ base: 6, sm: 6, md: 6 }} />
            <Box
              cursor="pointer"
              w={{ base: "24px", sm: "24px", lg: "24px" }}
              onClick={() => {
                navigate("https://twitter.com/2022Miam")
              }}
            >
              <StaticImage
                src={"../images/twitter.png"}
                alt="twitter"
                placeholder="blurred"
              />
            </Box>

            <Box w={{ base: 6, sm: 6, md: 6 }} />
            <Box
              cursor="pointer"
              w={{ base: "24px", sm: "24px", lg: "24px" }}
              onClick={() => {
                navigate("https://www.instagram.com/miam_network/")
              }}
            >
              <StaticImage
                src={"../images/linkedin.png"}
                alt="linkedin"
                placeholder="blurred"
              />
            </Box>

            <Box w={{ base: 6, sm: 6, md: 6 }} />
            <Box
              cursor="pointer"
              w={{ base: "24px", sm: "24px", lg: "24px" }}
              onClick={() => {
                navigate("https://www.youtube.com/channel/UCmvnGyJ5addbXKPUvL6x8JQ")
              }}
            >
              <StaticImage
                src={"../images/youtube.png"}
                alt="youtube"
                placeholder="blurred"
              />
            </Box>




          </Flex>
        </Box> 

        
      </Flex>

      <Center mt={7} color="#fff">
        @Metaverse Industry Association Macau All rights reserved
      </Center>
    </Box>
  )
}

export default Footer
