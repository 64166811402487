import React from "react"
import { Link, Box, Flex, Heading } from "@chakra-ui/react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ location }) => {
  return (
    // pc
    <Flex
      display={{ base: "none", sm: "none", md: "flex" }}
      justifyContent="space-between"
      alignItems="center"
      w={{ md: "46rem", lg: "71rem", xl: "71rem" }}
      margin="0 auto" 
      maxW="1100px"
    >
     {/* <StaticImage
                src= {"../images/headerMindfulnessCapital.png"}
                alt="headerMindfulnessCapital"
                placeholder="blurred"
      /> */}
       <Box
        color="#333333"
        fontWeight="500"
        fontSize={{ base: "xl", md: "2xl" }}
       >
           {"Metaverse Industry Association Macau"}
       </Box>
     
      <Flex h="16" alignItems="center">
        <Flex
          mr={{ md: "9", lg: "20" }}
          color="#333333"
          fontWeight="500"
          cursor="pointer"
          borderBottom="4px solid #333"
          h="16"
          alignItems="center"
        >
          {location.pathname === "/"
            ? "Home"
            : location.pathname === "/simplifiedChinese"
            ? "首页"
            : "首頁"}
        </Flex>
        <Flex
          mr={{ md: "9", lg: "20" }}
          fontWeight="500"
          color="#999"
          cursor="pointer"
          alignItems="center"
        >
          <AnchorLink href="#Portfolio">
            {" "}
            {location.pathname === "/"
              ? "News & Events"
              : location.pathname === "/simplifiedChinese"
              ? "活动新闻"
              : "活動新聞 "}
          </AnchorLink>
        </Flex>
        <Flex
          mr={{ md: "9", lg: "20" }}
          fontWeight="500"
          color="#999"
          cursor="pointer"
          alignItems="center"
        >
          <AnchorLink href="#About">
            {" "}
            {location.pathname === "/"
              ? "Our Members"
              : location.pathname === "/simplifiedChinese"
              ? "会员介绍"
              : "會員介紹 "}
          </AnchorLink>
        </Flex>
        <Flex>
          <Box
            mr={{ md: "1", lg: "3.5" }}
            fontWeight={location.pathname === "/" ? "600" : "normal"}
            cursor="pointer"
            onClick={() => {
              navigate("/")
            }}
            color={location.pathname === "/" ? "#000" : "#999"}
          >
            EN{" "}
          </Box>
          <Box borderLeft="1px dashed #999" />
          <Box
            ml={{ md: "1", lg: "3.5" }}
            mr={{ md: "1", lg: "3.5" }}
            cursor="pointer"
            onClick={() => {
              navigate("/simplifiedChinese")
            }}
            color={location.pathname === "/simplifiedChinese" ? "#000" : "#999"}
            fontWeight={
              location.pathname === "/simplifiedChinese" ? "600" : "normal"
            }
          >
            简
          </Box>
          <Box borderLeft="1px dashed #999" />
          <Box
            ml={{ md: "1", lg: "3.5" }}
            cursor="pointer"
            onClick={() => {
              navigate("/traditionalChinese")
            }}
            color={
              location.pathname === "/traditionalChinese" ? "#000" : "#999"
            }
            fontWeight={
              location.pathname === "/traditionalChinese" ? "600" : "normal"
            }
          >
            繁
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
